.buttonWrapper {
  display: inline-block;
}

.button {
  background: #fbfbfb;
  color: #888;
  font-size: 16px;
  border: 0;
  padding-left: 5px;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.button svg {
  fill: currentColor;
}

.button:hover,
.button:focus {
  background: #f3f3f3;
  outline: 0; /* reset for :focus */
}

.active {
  background: #efefef;
  color: #444;
}

.active svg {
  fill: #444;
}

.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #404040;
  font-size: 16px;
  border: 0;
  padding-left: 5px;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.headlineButton svg {
  fill: currentColor;
}
